import React from "react";

import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainMenu from "../../components/MainMenu/";
import { dateToView } from "../../Global/funcoes.js";
import "../../Global/styleGeral.css";

export default function Home({ history }) {
  const versoes = [
            {
      id: 19,
      producao: true,
      versao: "1.1.2",
      data_atualizacao: new Date("2024-11-22T03:00:00.000Z"),
      alteracoes: [
        "Fix: Otimizado carregamento de Evoluções"
      ],
    },
        {
      id: 18,
      producao: true,
      versao: "1.1.1",
      data_atualizacao: new Date("2024-07-17T03:00:00.000Z"),
      alteracoes: [
        "Correção na melhoria de carregamento",
        "Incluso controle do Financeiro",
        "Incluso controle de Fornecedores",
      ],
    },
    {
      id: 17,
      producao: false,
      versao: "1.0.16",
      data_atualizacao: new Date("2022-10-28T03:00:00.000Z"),
      alteracoes: [
        "Correção de travamentos no sistema",
        "Icones na aba de guia",
        "Número total de repetições em um nivi agendamento",
        "Alteração direta no popup de agendamento",
        "Mais de um proficional por agendamento",
        "Fechamento manual de guias",
        "Listagem das consultas nos dados do paciente",
        "Opção para replicar alterações de um agendamento para todos os agendamentos da guia",
      ],
    },
    {
      id: 16,
      producao: false,
      versao: "1.0.15",
      data_atualizacao: new Date("2022-09-13"),
      alteracoes: [
        "Corrigido filtro das guias (listar por padrão só as abertas)",
        "Transferido alerta de vencimento para o quadro",
        "Corrigido alerta de vencimento para não listar guias concluídas",
      ],
    },
    {
      id: 15,
      producao: false,
      versao: "1.0.14",
      data_atualizacao: new Date("2022-09-11"),
      alteracoes: [
        "Alerta de consultas",
        "Controle de guias",
        "Controle de tags",
        "Opção de excluir todas guias",
      ],
    },
    {
      id: 14,
      producao: false,
      versao: "1.0.13",
      data_atualizacao: new Date("2022-06-21"),
      alteracoes: ["Melhorias e ajustes no Quadro"],
    },
    {
      id: 13,
      producao: false,
      versao: "1.0.13",
      data_atualizacao: new Date("2022-06-20"),
      alteracoes: [
        "Adicionado Quadro de Funcionários",
        "Controle de horários dos profissionals",
        "Cor na Agenda ajustado com evolução",
        "Adionado várias pessoas no mesmo horário",
      ],
    },

    {
      id: 12,
      producao: false,
      versao: "1.0.12",
      data_atualizacao: new Date("2021-10-04"),
      alteracoes: [
        "Adicionado data de nascimento e informação de mês e dia no Diagnóstico",
      ],
    },

    {
      id: 11,
      producao: false,
      versao: "1.0.11",
      data_atualizacao: new Date("2021-07-08"),
      alteracoes: [
        "Adiciona Menu de Configurações, possibilitando alterar tempo da agenda e formato do financeiro.",
        "Lançamento financeiro por mensalidade",
      ],
    },
    {
      id: 10,
      producao: false,
      versao: "1.0.10",
      data_atualizacao: new Date("2020-12-29"),
      alteracoes: [
        "Adicionado data da primeira consulta no diagnóstico e cadastro de pessoa",
      ],
    },
    {
      id: 9,
      producao: false,
      versao: "1.0.9",
      data_atualizacao: new Date("2020-11-10"),
      alteracoes: [
        "Correção: Não estava refazendo a numerção das aulas quando era reagendado.",
      ],
    },
    {
      id: 8,
      producao: false,
      versao: "1.0.8",
      data_atualizacao: new Date("2020-11-06"),
      alteracoes: [
        "Implementação para possibilitar alteração do professor da agenda de pilates",
      ],
    },

    {
      id: 7,
      producao: false,
      versao: "1.0.7",
      data_atualizacao: new Date("2020-10-31"),
      alteracoes: [
        "Incluso campo número aula na tela onde é feito a geração das aulas para poder informar qual o número da aula que vai iniciar a geração",
        "Implementado para quando reagendar uma aula fazer o recalculo do número das aulas",
        "Correção na exclusão do cadastro de pessoa: Implementado para excluir todos os relacionamentos",
      ],
    },

    {
      id: 6,
      producao: false,
      versao: "1.0.6",
      data_atualizacao: new Date("2020-08-15"),
      alteracoes: [
        "Corrigido defeito na listagem das notas fiscais (Estava listando todas as notas)",
        "Implementado campo Exige Nota Fiscal no cadastro do paciente e exibido na visualização das agendas",
        "Adicionado número da aula na visualização da agenda de pilates",
      ],
    },

    {
      id: 5,
      producao: false,
      versao: "1.0.5",
      data_atualizacao: new Date("2020-08-15"),
      alteracoes: ["Alterado campo EVA para lista de Escalas de DOR"],
    },

    {
      id: 4,
      producao: false,
      versao: "1.0.4",
      data_atualizacao: new Date("2020-07-27"),
      alteracoes: [
        "Adicionado informações de profissão e idade ao selecionar paciente no diagnóstico",
      ],
    },

    {
      id: 3,
      producao: false,
      versao: "1.0.3",
      data_atualizacao: new Date("2020-07-08"),
      alteracoes: [
        "Alterado ordem e posicionamento dos campos do diagnóstico",
        "Alterado campo qualidade do sono para campo livre",
        "Correção em todos formulários que possibilitam edição para ao atualizar a página redirecionar para a listagem",
      ],
    },

    {
      id: 3,
      producao: false,
      versao: "1.0.2",
      data_atualizacao: new Date("2020-07-03"),
      alteracoes: [
        "Implementado possíbilidade de marcar lista de espera como agendada ou cancelada",
        "Implementado informação da última consulta do paciente no cadastro de pessoas",
      ],
    },

    {
      id: 2,
      producao: false,
      versao: "1.0.1",
      data_atualizacao: new Date("2020-05-20"),
      alteracoes: [
        "Adicionado Listas de Espera",
        "Adicionado Cancelamentos",
        "Adicionado NF no Paciente",
        "Alterado campo frequencia para Select com as opções Variável e Constante",
        "Incluso opção de Professor no cadastro do Paciente",
        "Incluso possíbilidade de marcar cadastro de pessoa como Paciente, Aluno e Professor simultaneamente",
        "Incluso CPF no cadastro do paciente",
        "Alterado intervalo entre eventos na agenda para 45 minutos",
        "Implementado envio de mensagens para usuários",
        "Adicionado Agenda Pilates",
        "Implementado geração do financeiro automático na agenda do consultório",
        "Adicionado opções para alterar a senha",
        "Implementado para solicitar se deseja realizar a geração de uma nova fatura quando feito pagamento parcial",
        "Melhorias e correções geral na aplicação",
      ],
    },

    {
      id: 1,
      producao: false,
      versao: "1.0.0",
      data_atualizacao: new Date("2020-04-28"),
      alteracoes: ["Versão inicial de testes"],
    },
  ];

  return (
    <div>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        Versões / Atualizações
        <a
          href="/ajuda#menu"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
      </div>

      {versoes.map((item) => {
        const alteracoes = item.alteracoes.map((alteracao) => (
          <li key={alteracao}>{alteracao}</li>
        ));

        return (
          <div key={item.id} className="container-principal">
            <h3>
              Versão {item.versao} {item.producao && <strong> (atual) </strong>}{" "}
            </h3>
            <p>Atualizado em {dateToView(item.data_atualizacao)}</p>

            <ul style={{ paddingLeft: "30px", color: "#b7b7b7" }}>
              {alteracoes}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
