import React from 'react';
import {Card, Button} from 'react-bootstrap';
import Delete from '../../pages/Delete/';
import moment from "moment";    

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFile, faPen } from "@fortawesome/free-solid-svg-icons";

export function CardEvolucao ({evolucao, setEvolucao, files, setModalFiles}) {
    const editEvolucao = () => {
        evolucao.editando = true;
        evolucao.data_evolucao = new Date(evolucao.data_evolucao);
        evolucao.files = files;

        setEvolucao(evolucao);
    }

    const viewEvolucaoFiles = () => {
        evolucao.visualizando = true;
        evolucao.files = files;

        setEvolucao(evolucao);
        setModalFiles(true);
    }

    return (
        <Card style={{backgroundColor:"#e6edff"}}>
            <Card.Body>
                <Card.Title>{moment(evolucao.data_evolucao).format('DD/MM/YYYY - HH:mm')}
                    <div style={{float:"right"}}>
                        {/*<Button type="button" variant="visualizar-arquivos" alt="Arquivos" title="Arquivos" onClick={()=>{viewEvolucaoFiles()}}><FontAwesomeIcon icon={faEye}/> Arquivos</Button>*/}
                        <Button type="button" variant="editar-listagem" alt="Editar" title="Editar" onClick={()=>{editEvolucao()}}><FontAwesomeIcon icon={faPen}/> Editar</Button>
                        <Delete type="button" variant="apagar-listagem" tipo="evolucoes"  alt="Apagar" title="Apagar" dados={evolucao}/>
                    </div>
                </Card.Title>
                <Card.Text>{evolucao.descricao}</Card.Text>
            </Card.Body>
        </Card>
    );
}
